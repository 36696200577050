import { css, Theme } from '@emotion/react';
import { Box } from '@material-ui/core';

import { SettingsSidebarList } from './SettingsSidebarList';
import { SettingsSidebarProfile } from './SettingsSidebarProfile';

const containerCss = (theme: Theme) => css`
  border-right: 1px solid ${theme.palette.divider};
`;

export const SettingsSidebar = () => {
  return (
    <Box css={containerCss}>
      <SettingsSidebarProfile />
      <SettingsSidebarList />
    </Box>
  );
};
