import { SystemRoleName } from '@jebel/constants';

import { useUserUpdateRolesMutation } from 'shared/graphql';

import { useSystemRoles } from './useSystemRoles';

export function useUpdateUserRoleAddons() {
  const { data: systemRoles, loading: systemRolesLoading } = useSystemRoles();

  const [userUpdateRolesMutation, { loading: isUpdating }] = useUserUpdateRolesMutation({
    refetchQueries: ['SchoolMembersReport'],
  });

  const updateUserRoles = async (userId: string, rolesAddonsIds: string[]) => {
    await userUpdateRolesMutation({
      variables: {
        input: {
          id: userId,
          rolesAddonsIds,
        },
      },
    });
  };

  const getRoleByName = (name: string) => {
    // Allow to find the given role addon using `name` as filter.
    // https://8base-dev.atlassian.net/browse/JEB-1600
    return systemRoles.find(role => role.name === name || role.name === SystemRoleName[name]);
  };

  return {
    /** @deprecated Use `useSystemRoles` instead. */
    roles: systemRoles,
    updateUserRoles,
    isLoading: systemRolesLoading,
    isUpdating,
    getRoleByName,
  };
}
