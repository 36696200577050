import { Theme, css } from '@emotion/react';
import { Box, Tooltip, Typography } from '@material-ui/core';
import { HelpOutline } from '@mui/icons-material';

import { humanizeText } from '@jebel/utils';

import { CheckboxField } from 'shared/components/form';
import { FormTextField, FormCheckbox, AutocompleteField } from 'shared/components/ui';
import type { SchoolConfigurationLayout } from 'shared/graphql';
import { useSystemRoles } from 'shared/hooks';
import { RoleAddonPermission } from 'shared/types';

const containerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const sectionListCSS = (theme: Theme) => css`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  ${theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
  }
`;

const sectionTitleCSS = css`
  font-weight: bold;
`;

const additionalAccessesCSS = css`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
  gap: 1rem;
`;

const additionalAccessCSS = css`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const permissionCSS = css`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

interface RoleAddonPermissionSectionPermission {
  value: keyof RoleAddonPermission;
  label: string;
  description?: string;
}

interface RoleAddonPermissionSection {
  id: keyof RolesEditFormPermissionsValues;
  title: string;
  description?: string;
  permissions: RoleAddonPermissionSectionPermission[];
}

const ROLE_ADDON_PERMISSION_SECTIONS: RoleAddonPermissionSection[] = [
  {
    id: 'homePostsPermissions',
    title: 'Home Posts',

    permissions: [
      {
        value: 'add',
        label: 'Add',
        description: 'Allow to create posts, comments and like on the home feed.',
      },
      {
        value: 'edit',
        label: 'Edit',
        description: 'Allow to edit their own posts on the home feed.',
      },
      {
        value: 'delete',
        label: 'Delete',
        description: 'Allow to delete posts on the home feed.',
      },
    ],
  },
  {
    id: 'schoolPostsPermissions',
    title: 'School Posts',
    permissions: [
      {
        value: 'add',
        label: 'Add',
        description: 'Allow to create posts on the school news feed.',
      },
      {
        value: 'edit',
        label: 'Edit',
        description: 'Allow to edit posts on the school news feed.',
      },
      {
        value: 'delete',
        label: 'Delete',
        description: 'Allow to delete posts and comments on the school news feed.',
      },
    ],
  },
  {
    id: 'membersPermissions',
    title: 'Memberships',
    permissions: [
      {
        value: 'add',
        label: 'Add',
        description: 'Allow to invite new members to the school.',
      },
      {
        value: 'edit',
        label: 'Edit',
        description: `Allow to edit other's memberships. Only through school administration access.`,
      },
    ],
  },
  {
    id: 'businessPermissions',
    title: 'Business',
    permissions: [
      {
        value: 'add',
        label: 'Add',
        description: 'Allow to claim businesses.',
      },
    ],
  },
  {
    id: 'forumPermissions',
    title: 'Forum',
    permissions: [
      {
        value: 'add',
        label: 'Add',
        description: 'Allow to create forum posts.',
      },
      {
        value: 'delete',
        label: 'Delete',
        description: 'Allow to delete forum posts.',
      },
    ],
  },
  {
    id: 'groupsPermissions',
    title: 'Groups',
    permissions: [
      {
        value: 'add',
        label: 'Add',
        description: 'Allow to create groups.',
      },
      {
        value: 'edit',
        label: 'Edit',
        description: 'Allow to edit their own groups and manage their members and posts.',
      },
      {
        value: 'delete',
        label: 'Delete',
        description: 'Allow to delete groups. Only through school administration access.',
      },
    ],
  },
];

export interface RolesEditFormPermissionsValues {
  homePostsPermissions: RoleAddonPermission;
  schoolPostsPermissions: RoleAddonPermission;
  membersPermissions: RoleAddonPermission;
  businessPermissions: RoleAddonPermission;
  forumPermissions: RoleAddonPermission;
  groupsPermissions: RoleAddonPermission;
}

export interface RolesEditFormValues extends RolesEditFormPermissionsValues {
  name: string;
  systemRoleId?: string;
  sections: Record<string, boolean>;
  isDefault: boolean;
}

export interface RoleFormProps {
  layouts: SchoolConfigurationLayout[];
  isLoading: boolean;
}

export function RoleEditForm({ layouts }: RoleFormProps) {
  const { data: systemRoles, loading: systemRolesLoading } = useSystemRoles();

  return (
    <Box css={containerCSS}>
      <FormTextField
        inputProps={{
          label: 'Role Name',
          variant: 'outlined',
          required: true,
        }}
        fieldProps={{ name: 'name', required: true }}
      />

      <AutocompleteField
        required
        name="systemRoleId"
        label="System Role"
        disabled={systemRolesLoading}
        options={systemRoles.map(role => ({
          value: role.id as string,
          label: humanizeText(role.name as string),
        }))}
      />

      <FormCheckbox
        fieldProps={{ name: `isDefault` }}
        checkboxProps={{
          color: 'primary',
          label: 'Set this role to new users',
        }}
      />

      <Typography css={sectionTitleCSS}>Role has access to view these sections:</Typography>

      <Box css={sectionListCSS}>
        {layouts.map(layout => (
          <Box key={layout.id}>
            <CheckboxField
              name={`sections[${layout.id}]`}
              label={layout.name!}
              disabled={layout.name === 'Home'}
            />
          </Box>
        ))}
      </Box>

      <Typography css={sectionTitleCSS}>Role has additional access to:</Typography>

      <Box css={additionalAccessesCSS}>
        {ROLE_ADDON_PERMISSION_SECTIONS.map(section => (
          <Box key={section.id} css={additionalAccessCSS}>
            <Typography variant="body2">{section.title}</Typography>

            {section.permissions.map(permission => {
              const name = `[${section.id}][${permission.value}]`;

              return (
                <Box key={name} css={permissionCSS}>
                  <CheckboxField name={name} label={permission.label} />

                  {permission.description && (
                    <Tooltip arrow title={permission.description!}>
                      <HelpOutline fontSize="inherit" color="action" style={{ fontSize: 18 }} />
                    </Tooltip>
                  )}
                </Box>
              );
            })}
          </Box>
        ))}
      </Box>
    </Box>
  );
}
