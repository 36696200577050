import { FormControl, FormHelperText } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { ReactNode, useMemo } from 'react';

import { FormFieldValidation } from '@jebel/utils';

import { getIsInvalid } from 'shared/components/ui';
import { SwitchToggle, SwitchToggleProps } from 'shared/components/ui';

import { Label } from './SwitchField.styles';

export interface SwitchFieldProps extends Omit<SwitchToggleProps, 'size'> {
  /**
   * The name of the field in the formik form.
   */
  name: string;

  /**
   * The label to display above the field.
   */
  label?: string;

  /**
   * The validation function for the field.
   */
  validate?: FormFieldValidation<string>;

  /**
   * The helper text to display below the field.
   */
  helperText?: ReactNode;
}

export function SwitchField(props: SwitchFieldProps) {
  const form = useFormikContext();

  const [field, meta, helpers] = useField<boolean>({
    name: props.name,
    validate: props.validate,
  });

  const showError = useMemo(() => getIsInvalid({ meta, form }), [meta, form]);

  const message = useMemo(() => {
    return showError ? meta.error : props.helperText;
  }, [showError, meta.error, props.helperText]);

  const handleChange = (_event: unknown, value: boolean) => {
    helpers.setValue(value);
  };

  const Control = (
    <SwitchToggle {...props} size="small" checked={field.value} onChange={handleChange} />
  );

  return (
    <FormControl>
      {props.label ? <Label label={props.label} control={Control} /> : Control}

      {Boolean(message) && <FormHelperText error={showError}>{message}</FormHelperText>}
    </FormControl>
  );
}
