import { UserSchoolMembershipStatus } from './userSchoolMembership';
export var UserStatus;
(function (UserStatus) {
    UserStatus["Active"] = "active";
    UserStatus["Inactive"] = "inactive";
})(UserStatus || (UserStatus = {}));
/** @deprecated Use {@linkcode UserSchoolMembershipStatus} instead. */
export const USER_STATUSES = {
    active: UserSchoolMembershipStatus.Active,
    inactive: UserSchoolMembershipStatus.Inactive,
    pending: UserSchoolMembershipStatus.Pending,
    rejected: UserSchoolMembershipStatus.Rejected,
    invitationSent: UserSchoolMembershipStatus.InvitationSent,
};
export const USER_STATUS_TITLES = {
    [UserSchoolMembershipStatus.Active]: 'Active',
    [UserSchoolMembershipStatus.Inactive]: 'Inactive',
    [UserSchoolMembershipStatus.Pending]: 'Pending',
    [UserSchoolMembershipStatus.Rejected]: 'Rejected',
    [UserSchoolMembershipStatus.InvitationSent]: 'Invited',
};
export var UserAffiliations;
(function (UserAffiliations) {
    UserAffiliations["Alumni"] = "alumni";
    UserAffiliations["Parent"] = "parent";
    UserAffiliations["Student"] = "student";
    UserAffiliations["Staff"] = "staff";
    /** Requires `affiliationDescription`. */
    UserAffiliations["Other"] = "other";
})(UserAffiliations || (UserAffiliations = {}));
/** @deprecated Use {@linkcode UserAffiliations} instead. */
export const USER_AFFILIATIONS = {
    alumni: UserAffiliations.Alumni,
    parent: UserAffiliations.Parent,
    student: UserAffiliations.Student,
    staff: UserAffiliations.Staff,
    other: UserAffiliations.Other,
};
export const USER_GENDERS = {
    male: 'male',
    female: 'female',
};
export const USER_HOBBIES = {
    beach: 'beach',
    bicycling: 'bicycling',
    billiards: 'billiards',
    boating: 'boating',
    bowling: 'bowling',
    camping: 'camping',
    churchActivities: 'churchActivities',
    computer: 'computer',
    cooking: 'cooking',
    crafts: 'crafts',
    dancing: 'dancing',
    dating: 'dating',
    eatingOut: 'eatingOut',
    entertaining: 'entertaining',
    exercise: 'exercise',
    familyTime: 'familyTime',
    fishing: 'fishing',
    gardening: 'fishing',
    goingToMovies: 'goingToMovies',
    golf: 'golf',
    hiking: 'hiking',
    horsebackRiding: 'horsebackRiding',
    housework: 'housework',
    hunting: 'hunting',
    listeningToMusic: 'listeningToMusic',
    motorcycling: 'motorcycling',
    painting: 'painting',
    pets: 'pets',
    playingCards: 'playingCards',
    playingMusic: 'playingMusic',
    reading: 'reading',
    relaxing: 'relaxing',
    rentingMovies: 'rentingMovies',
    running: 'running',
    sewing: 'sewing',
    shopping: 'shopping',
    skiing: 'skiing',
    sleeping: 'sleeping',
    socializing: 'socializing',
    swimming: 'swimming',
    teamSports: 'teamSports',
    tennis: 'tennis',
    theater: 'theater',
    traveling: 'traveling',
    volunteerWork: 'volunteerWork',
    walking: 'walking',
    watchingSports: 'watchingSports',
    watchingTv: 'watchingTv',
    workingOnCars: 'workingOnCars',
    writing: 'writing',
};
export const USER_CLUBS = {
    printingClub: 'printingClub',
    airsoftClub: 'airsoftClub',
    anglersClub: 'anglersClub',
    artHistory: 'artHistory',
    artPhotographyClub: 'artPhotographyClub',
    beTheLight: 'beTheLight',
    bestBuddies: 'bestBuddies',
    blackStudentOrganization: 'blackStudentOrganization',
    businessClub: 'businessClub',
    cangrejos: 'cangrejos',
    carClub: 'carClub',
    cCNNLive: 'cCNNLive',
    chess: 'chess',
    clubPurpleEpilepsyAwareness: 'clubPurpleEpilepsyAwareness',
    codingClub: 'codingClub',
    columbusFilms: 'columbusFilms',
    comicBookClub: 'comicBookClub',
    cookingClub: 'cookingClub',
    cybersecurity: 'cybersecurity',
    debate: 'debate',
    dJsforACause: 'dJsforACause',
    dramaClub: 'dJsforACause',
    environmentalClub: 'environmentalClub',
    fellowshipOfChristianAthletes: 'fellowshipOfChristianAthletes',
    frenchClub: 'frenchClub',
    futureBusinessLeadersOfAmerica: 'futureBusinessLeadersOfAmerica',
    geographyClub: 'geographyClub',
    gymRatClub: 'gymRatClub',
    hideAndSeekClub: 'hideAndSeekClub',
    historyClub: 'historyClub',
    internationalThespiansHonorSociety: 'internationalThespiansHonorSociety',
    intramurals: 'intramurals',
    investmentClub: 'investmentClub',
    keyClub: 'keyClub',
    mathHonorSociety: 'mathHonorSociety',
    mindfulnessClub: 'mindfulnessClub',
    mockTrial: 'mockTrial',
    modelUN: 'modelUN',
    origamiEngineeringClub: 'origamiEngineeringClub',
    paintballClub: 'origamiEngineeringClub',
    patriotClub: 'patriotClub',
    preLawClub: 'preLawClub',
    preMedClub: 'preMedClub',
    projectLastDrop: 'projectLastDrop',
    respectLifeClub: 'respectLifeClub',
    rhoKappaNationalHonorSociety: 'rhoKappaNationalHonorSociety',
    roboticsClub: 'roboticsClub',
    rollD20: 'rollD20',
    sadd: 'sadd',
    scubaDivingClub: 'scubaDivingClub',
    spanishClub: 'spanishClub',
    spearfishingClub: 'spearfishingClub',
    squiresClub: 'squiresClub',
    standForTheSilent: 'standForTheSilent',
    starWarsClub: 'starWarsClub',
    sTEMClub: 'sTEMClub',
    studentActivitiesCommittee: 'studentActivitiesCommittee',
    theFlyingClub: 'theFlyingClub',
    theLog: 'theLog',
    theMindfulnessClub: 'theMindfulnessClub',
    archery: 'archery',
    badminton: 'badminton',
    baseball: 'baseball',
    basketball: 'basketball',
    bowling: 'bowling',
    competativeSpirit: 'competativeSpirit',
    crossCountry: 'crossCountry',
    dance: 'dance',
    fieldHockey: 'fieldHockey',
    flagFootball: 'flagFootball',
    football: 'football',
    golf: 'golf',
    gymnastics: 'gymnastics',
    iceHockey: 'iceHockey',
    lacrosse: 'lacrosse',
    masScholar: 'masScholar',
    soccer: 'soccer',
    softball: 'softball',
    swimmingNDiving: 'swimmingNDiving',
    tennis: 'tennis',
    trackNField: 'trackNField',
    volleyball: 'volleyball',
    waterPolo: 'waterPolo',
    weightlifting: 'weightlifting',
    wrestling: 'wrestling',
};
