import { useSystemRolesQuery } from 'shared/graphql';

type Options = Parameters<typeof useSystemRolesQuery>[0];

/**
 * A custom hook that fetches and provides system roles data.
 */
export function useSystemRoles(options?: Options) {
  const { data: response, loading } = useSystemRolesQuery(options);

  const data = response?.systemRoles.items ?? [];

  return {
    data,
    loading,
  };
}
