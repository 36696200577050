import { Filelink as FileLinkBuilder, } from 'filestack-js';
import { isObj } from '../types';
/**
 * Extracts the security policy from a given URL.
 *
 * This function searches for a pattern `policy:<policy>` within the URL
 * and returns the extracted policy if found.
 *
 * @param url - The URL string to extract the security policy from.
 * @returns The extracted security policy if found, otherwise `undefined`.
 */
function extractSecurityPolicy(url) {
    var _a;
    const matches = url.match(/security=(policy|p):(?<policy>[^,\\/]+)/);
    return (_a = matches === null || matches === void 0 ? void 0 : matches.groups) === null || _a === void 0 ? void 0 : _a.policy;
}
/**
 * Extracts the security signature from a given URL.
 *
 * This function searches for a pattern `signature:<signature>` within the URL
 * and returns the extracted signature if found.
 *
 * @param url - The URL string to extract the security signature from.
 * @returns The extracted security signature if found, otherwise `undefined`.
 */
function extractSecuritySignature(url) {
    var _a;
    const matches = url.match(/security=.+(signature|s):(?<signature>[^,\\/]+)/);
    return (_a = matches === null || matches === void 0 ? void 0 : matches.groups) === null || _a === void 0 ? void 0 : _a.signature;
}
/**
 * Extracts the source part from a given URL.
 *
 * This function uses a regular expression to match the last segment of the URL path
 * and returns it as the source. If no match is found, it returns `undefined`.
 *
 * @param url - The URL string from which to extract the source.
 * @returns The extracted source string or `undefined` if no match is found.
 */
function extractSource(url) {
    var _a;
    const matches = url.match(/\/(?<source>[^\\/]+)$/);
    return (_a = matches === null || matches === void 0 ? void 0 : matches.groups) === null || _a === void 0 ? void 0 : _a.source;
}
/**
 * Processes a **Filestack URL** with the given configuration options.
 *
 * @param url - The original **Filestack URL** to be processed.
 * @param options - An optional configuration object to apply various transformations.
 * @returns The processed Filestack URL as a `string`.
 */
export function processFilestackUrl(url, options = {}) {
    const source = extractSource(url);
    if (!source) {
        return url;
    }
    const link = new FileLinkBuilder(source);
    if (options.resize) {
        link.resize(options.resize);
    }
    if (options.crop) {
        link.crop(options.crop);
    }
    if (options.rotate) {
        link.rotate(options.rotate);
    }
    if (options.flip) {
        link.flip();
    }
    if (options.flop) {
        link.flop();
    }
    if (options.compress) {
        link.compress(isObj(options.compress) ? options.compress : undefined);
    }
    if (options.pjpg) {
        link.pjpg(options.pjpg);
    }
    if (options.quality) {
        link.quality(options.quality);
    }
    if (options.output) {
        link.output(options.output);
    }
    if (options.roundedCorners) {
        link.roundedCorners(isObj(options.roundedCorners) ? options.roundedCorners : undefined);
    }
    const securityPolicy = extractSecurityPolicy(url);
    const securitySignature = extractSecuritySignature(url);
    if (securityPolicy && securitySignature) {
        // Apply security policy and signature to the link.
        // This is required for processing URLs that are protected by security policies.
        link.security({
            policy: securityPolicy,
            signature: securitySignature,
        });
    }
    return link.toString();
}
const RESIZE_VARIANTS = [0.25, 0.5, 1, 1.5, 2];
/**
 * Generates a srcset attribute value for an image URL processed by Filestack.
 *
 * @param url - The original image URL to be processed. Can be null or undefined.
 * @param options - Optional parameters to customize the processing of the URL.
 * @returns A string representing the srcset attribute value, or undefined if the URL is null or undefined.
 *
 * The function processes the given URL with the specified options. If resizing is enabled in the options,
 * it generates multiple URLs with different scales (1x to 4x) and combines them into a srcset string.
 * If resizing is not enabled, it simply processes the URL with the given options.
 */
export function processFilestackUrlSrcSet(url, options = {}) {
    if (!url) {
        return undefined;
    }
    if (!options.resize) {
        return processFilestackUrl(url, options);
    }
    const resize = { ...options.resize };
    const scales = RESIZE_VARIANTS.map(scale => {
        const setOptions = { ...options, resize: { ...resize } };
        if (resize.width) {
            // Scale the width by the given factor.
            setOptions.resize.width = resize.width * scale;
        }
        if (resize.height) {
            // Scale the height by the given factor.
            setOptions.resize.height = resize.height * scale;
        }
        const scaleURL = processFilestackUrl(url, setOptions);
        return `${scaleURL} ${scale}x`;
    });
    return scales.join(', ');
}
