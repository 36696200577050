import type {
  SchoolConfigurationLayout,
  RoleAddonInfoFragment,
  RoleAddonCreateInput,
  RoleAddonUpdateInput,
  SchoolConfigurationLayoutKeyFilter,
} from 'shared/graphql';
import { RolesEditFormValues } from '../RoleEditForm';
import { RoleFormMode } from '../Roles';

const findAccessibleLayout = (
  accessibleLayouts: RoleAddonInfoFragment['accessibleLayouts'],
  id: string | undefined | null,
) => {
  if (!accessibleLayouts) {
    return false;
  }

  return Boolean(accessibleLayouts?.items.find(layout => layout.id === id));
};

export const buildSectionsValue = (
  layouts: SchoolConfigurationLayout[],
  accessibleLayouts: RoleAddonInfoFragment['accessibleLayouts'],
): RolesEditFormValues['sections'] => {
  return Object.fromEntries(
    layouts.map(layout => {
      if (layout.name === 'Home') {
        // Home layout is always accessible.
        return [layout.id as string, true];
      }

      return [layout.id as string, findAccessibleLayout(accessibleLayouts, layout.id)];
    }),
  );
};

const buildKeyFilterOfLayoutsId = (
  layouts: RolesEditFormValues['sections'],
): SchoolConfigurationLayoutKeyFilter[] => {
  return Object.entries(layouts)
    .filter(layout => layout[1])
    .map(layout => ({ id: layout[0] }));
};

export const buildRoleAddonCreateOnUpdateInput = (
  formValues: RolesEditFormValues,
  formMode: RoleFormMode,
): RoleAddonCreateInput | RoleAddonUpdateInput => {
  return {
    name: formValues.name,
    accessibleLayouts: {
      [formMode === 'create' ? 'connect' : 'reconnect']: buildKeyFilterOfLayoutsId(
        formValues.sections,
      ),
    },
    isDefault: formValues.isDefault,
    role: {
      connect: {
        id: formValues.systemRoleId,
      },
    },
    homePostsPermissions: formValues.homePostsPermissions,
    schoolPostsPermissions: formValues.schoolPostsPermissions,
    membersPermissions: formValues.membersPermissions,
    businessPermissions: formValues.businessPermissions,
    forumPermissions: formValues.forumPermissions,
    groupsPermissions: formValues.groupsPermissions,
  };
};
