import { Theme, css } from '@emotion/react';
import { Add, Edit } from '@material-ui/icons';
import { Box, MenuItem, Tab, Tabs } from '@mui/material';
import { Formik } from 'formik';
import { Fragment, SyntheticEvent, useMemo, useState } from 'react';

import { RouteLayout, Select, Button, LoadingOverlay } from 'shared/components/ui';
import { useRoleAddons, useSchoolConfiguration, useToast } from 'shared/hooks';
import { RoleAddonPermission } from 'shared/types';
import { recordError } from 'shared/utils/record';

import { SettingsTitle } from '../SettingsTitle';
import { RoleEditForm, type RolesEditFormValues } from './RoleEditForm';
import { buildRoleAddonCreateOnUpdateInput, buildSectionsValue } from './utils/utils';

const containerCSS = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;

  ${theme.breakpoints.down('md')} {
    padding: 1rem;
  }
`;

const footerCSS = css`
  display: flex;
  justify-content: end;
  gap: 0.5rem;
`;

const NEW_ROLE_ID = 'new_role';

export enum RoleFormMode {
  Create = 'create',
  Edit = 'edit',
}

const DEFAULT_PERMISSION: RoleAddonPermission = {
  add: false,
  edit: false,
  delete: false,
};

export function Roles() {
  const [selectedRole, setSelectedRole] = useState<string>();
  const [mode, setMode] = useState(RoleFormMode.Create);

  const {
    rolesAddons,
    createRoleAddon,
    updateRoleAddon,
    isLoading: loading,
    isUpdating: updating,
    isCreating: creating,
  } = useRoleAddons();

  const { configuration } = useSchoolConfiguration();
  const { showError, showSuccess } = useToast();

  const currentRole = useMemo(() => {
    if (mode === RoleFormMode.Create) {
      return { id: NEW_ROLE_ID };
    }

    return rolesAddons.find(role => role.id === selectedRole);
  }, [selectedRole, rolesAddons]);

  const layouts = useMemo(() => {
    if (!configuration?.layout) {
      return [];
    }

    const items = configuration?.layout.items ?? [];
    return items.filter(item => !item.isAlwaysShown);
  }, [configuration?.layout]);

  const initials = useMemo<RolesEditFormValues>(() => {
    const initials: RolesEditFormValues = {
      name: currentRole?.name ?? '',
      systemRoleId: currentRole?.role?.id ?? '',
      isDefault: Boolean(currentRole?.isDefault),
      sections: buildSectionsValue(layouts, currentRole?.accessibleLayouts),
      homePostsPermissions: currentRole?.homePostsPermissions || DEFAULT_PERMISSION,
      schoolPostsPermissions: currentRole?.schoolPostsPermissions || DEFAULT_PERMISSION,
      membersPermissions: currentRole?.membersPermissions || DEFAULT_PERMISSION,
      businessPermissions: currentRole?.businessPermissions || DEFAULT_PERMISSION,
      forumPermissions: currentRole?.forumPermissions || DEFAULT_PERMISSION,
      groupsPermissions: currentRole?.groupsPermissions || DEFAULT_PERMISSION,
    };

    return initials;
  }, [layouts, currentRole]);

  const onSubmit = async (formValues: RolesEditFormValues) => {
    try {
      if (mode === RoleFormMode.Create) {
        const newRoleId = await createRoleAddon(
          buildRoleAddonCreateOnUpdateInput(formValues, mode),
        );

        showSuccess('Role created successfully');

        if (newRoleId) {
          setSelectedRole(newRoleId);
          setMode(RoleFormMode.Edit);
        }
      } else {
        if (!selectedRole) {
          throw new Error('Role not selected');
        }

        await updateRoleAddon(selectedRole, buildRoleAddonCreateOnUpdateInput(formValues, mode));

        showSuccess('Role updated successfully');
      }
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message);
      }
    }
  };

  const handleChangeMode = (_event: SyntheticEvent, newMode: RoleFormMode) => {
    setMode(newMode);
    setSelectedRole(undefined);
  };

  return (
    <RouteLayout>
      <SettingsTitle>Roles and Permissions</SettingsTitle>

      <Tabs value={mode} variant="fullWidth" onChange={handleChangeMode}>
        <Tab
          label="Create a role"
          iconPosition="start"
          icon={<Add />}
          value={RoleFormMode.Create}
        />

        <Tab label="Edit a role" iconPosition="start" icon={<Edit />} value={RoleFormMode.Edit} />
      </Tabs>

      <Box css={containerCSS}>
        {mode === RoleFormMode.Edit && (
          <Select
            label="Role to be edited"
            variant="outlined"
            disabled={loading || updating}
            value={selectedRole}
            required
            onChange={event => setSelectedRole(String(event.target.value))}
          >
            {rolesAddons.map(role => (
              <MenuItem key={role.id} value={role.id || undefined}>
                {role.name || role.role?.name || 'Unknown name'}
              </MenuItem>
            ))}
          </Select>
        )}

        <LoadingOverlay loading={loading}>
          <Formik onSubmit={onSubmit} initialValues={initials} enableReinitialize>
            {form => (
              <Fragment>
                <RoleEditForm layouts={layouts} isLoading={loading || updating || creating} />

                <Box css={footerCSS}>
                  <Button
                    disabled={!form.dirty}
                    loading={form.isSubmitting}
                    variant="outlined"
                    color="primary"
                    onClick={() => form.resetForm()}
                  >
                    Cancel
                  </Button>

                  <Button
                    disabled={!form.dirty}
                    loading={form.isSubmitting}
                    variant="contained"
                    color="primary"
                    onClick={() => form.submitForm()}
                  >
                    {mode === RoleFormMode.Create ? 'Add Role' : 'Save changes'}
                  </Button>
                </Box>
              </Fragment>
            )}
          </Formik>
        </LoadingOverlay>
      </Box>
    </RouteLayout>
  );
}
