var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _FilterBuilder_unions, _FilterBuilder_intersections, _FilterBuilder_initial;
export class FilterBuilder {
    constructor(initial) {
        var _a, _b;
        _FilterBuilder_unions.set(this, void 0);
        _FilterBuilder_intersections.set(this, void 0);
        _FilterBuilder_initial.set(this, void 0);
        if (Array.isArray(initial.AND)) {
            __classPrivateFieldSet(this, _FilterBuilder_unions, initial.AND, "f");
            // Remove the initial AND clause to avoid duplication.
            delete initial.AND;
        }
        if (Array.isArray(initial.OR)) {
            __classPrivateFieldSet(this, _FilterBuilder_intersections, initial.OR, "f");
            // Remove the initial OR clause to avoid duplication.
            delete initial.OR;
        }
        __classPrivateFieldSet(this, _FilterBuilder_unions, (_a = __classPrivateFieldGet(this, _FilterBuilder_unions, "f")) !== null && _a !== void 0 ? _a : [], "f");
        __classPrivateFieldSet(this, _FilterBuilder_intersections, (_b = __classPrivateFieldGet(this, _FilterBuilder_intersections, "f")) !== null && _b !== void 0 ? _b : [], "f");
        __classPrivateFieldSet(this, _FilterBuilder_initial, initial, "f");
    }
    and(...rules) {
        for (const rule of rules) {
            if (rule instanceof FilterBuilder) {
                this.and(rule.build());
                continue;
            }
            __classPrivateFieldGet(this, _FilterBuilder_unions, "f").push(rule);
        }
        return this;
    }
    or(...rules) {
        for (const rule of rules) {
            if (rule instanceof FilterBuilder) {
                this.or(rule.build());
                continue;
            }
            __classPrivateFieldGet(this, _FilterBuilder_intersections, "f").push(rule);
        }
        return this;
    }
    build() {
        const filter = { ...__classPrivateFieldGet(this, _FilterBuilder_initial, "f") };
        const intersectionsCount = Number(__classPrivateFieldGet(this, _FilterBuilder_intersections, "f").length);
        const unionsCount = Number(__classPrivateFieldGet(this, _FilterBuilder_unions, "f").length);
        if (intersectionsCount === 0 && unionsCount === 1) {
            // Returns the only union if there are no intersections.
            return { ...filter, ...__classPrivateFieldGet(this, _FilterBuilder_unions, "f")[0] };
        }
        const rules = Number(Object.keys(filter).length);
        if (intersectionsCount === 1 && unionsCount === 0 && rules === 0) {
            // Returns the only intersection if there are no unions.
            return { ...__classPrivateFieldGet(this, _FilterBuilder_intersections, "f")[0] };
        }
        if (intersectionsCount > 0) {
            filter.OR = __classPrivateFieldGet(this, _FilterBuilder_intersections, "f");
        }
        if (unionsCount > 0) {
            filter.AND = __classPrivateFieldGet(this, _FilterBuilder_unions, "f");
        }
        return filter;
    }
}
_FilterBuilder_unions = new WeakMap(), _FilterBuilder_intersections = new WeakMap(), _FilterBuilder_initial = new WeakMap();
/**
 * Creates a new instance of `FilterBuilder` with optional initial filter values.
 *
 * @template R - Type extending `GenericFilter` for type-safe filter operations.
 * @param {R | undefined | null} initial - Initial filter values to populate the builder.
 * @returns {FilterBuilder<R>} A new `FilterBuilder` instance with the provided initial values.
 */
export function createFilterBuilder(initial) {
    return new FilterBuilder(initial !== null && initial !== void 0 ? initial : {});
}
