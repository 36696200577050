import styled from '@emotion/styled';
import { Switch, SwitchProps } from '@material-ui/core';

const StyledSwitch = styled(Switch)`
  --switch-height: ${p => (p.size === 'small' ? '1.5rem' : '2rem')};
  --switch-padding: ${p => (p.size === 'small' ? '0.15rem' : '0.25rem')};

  width: calc(var(--switch-height) * 1.6);
  height: var(--switch-height);
  border-radius: var(--switch-height);
  padding: 0;

  & .MuiSwitch-switchBase {
    padding: 0;
    margin: 20;
    transition-duration: 300ms;
    transform: translate(var(--switch-padding), var(--switch-padding));

    &.Mui-checked {
      transform: translate(calc(var(--switch-height) * 0.65), var(--switch-padding));
      color: #fff;

      & + .MuiSwitch-track {
        background-color: ${p => p.theme.palette.secondary.main};
        opacity: 1;
        border: 0;
      }

      &.Mui-disabled + .MuiSwitch-track {
        opacity: 0.5;
      }
    }

    &.Mui-focusVisible .MuiSwitch-thumb {
      color: ${p => p.theme.palette.secondary.main};
      border: 6px solid white;
    }

    &.Mui-disabled .MuiSwitch-thumb {
      color: ${p => p.theme.palette.action.disabled};
    }

    & .MuiSwitch-thumb {
      --switch-thumb-size: calc(var(--switch-height) - (var(--switch-padding) * 2));

      box-sizing: border-box;
      width: var(--switch-thumb-size);
      height: var(--switch-thumb-size);
    }

    & .MuiSwitch-track {
      border-radius: var(--switch-height);
      background-color: #e9e9ea;
      opacity: 1;
    }
  }
`;

export type SwitchToggleProps = SwitchProps;

export function SwitchToggle(props: SwitchToggleProps) {
  return <StyledSwitch {...props} />;
}
